import React from 'react'
import jwt_decode from 'jwt-decode'
import axios from 'axios'
import { toast } from 'react-toastify'
import { useEffect, useRef } from 'react'
import fileDownload from 'js-file-download'
import { navigate } from 'gatsby-link'

export const isIterableArray = array => Array.isArray(array) && !!array.length

//===============================
// Store
//===============================
const windowGlobal = typeof window !== 'undefined' && window
const localStorageVar = windowGlobal ? localStorage : null

export const getItemFromStore = (key, defaultValue, store = localStorageVar) =>
  store ? JSON.parse(store.getItem(key)) || defaultValue : defaultValue
export const setItemToStore = (key, payload, store = localStorageVar) =>
  store ? store.setItem(key, JSON.stringify(payload)) : null
export const getStoreSpace = (store = localStorageVar) =>
  parseFloat((escape(encodeURIComponent(JSON.stringify(store))).length / (1024 * 1024)).toFixed(2))

//===============================
// Cookie
//===============================
export const getCookieValue = name => {
  const value = document.cookie.match('(^|[^;]+)\\s*' + name + '\\s*=\\s*([^;]+)')
  return value ? value.pop() : null
}

export const createCookie = (name, value, cookieExpireTime) => {
  const date = new Date()
  date.setTime(date.getTime() + cookieExpireTime)
  const expires = '; expires=' + date.toUTCString()
  document.cookie = name + '=' + value + expires + '; path=/'
}


//===============================
// Colors
//===============================
export const hexToRgb = hexValue => {
  let hex
  hexValue.indexOf('#') === 0 ? (hex = hexValue.substring(1)) : (hex = hexValue)
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(
    hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b)
  )
  return result ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)] : null
}

export const rgbColor = (color = colors[0]) => `rgb(${hexToRgb(color)})`
export const rgbaColor = (color = colors[0], alpha = 0.5) => `rgba(${hexToRgb(color)},${alpha})`

export function StoreData(data, store = localStorageVar) {
  for (const [key, value] of Object.entries(data)) {
    store.setItem(key, value)
  }
}

export const removeObjectNull = (obj) => Object.entries(obj).reduce((a, [k, v]) => (v ? (a[k] = v, a) : a), {})


export const PrivateRoute = ({ component: Component, location, ...rest }) => {
  if (!CheckToken()) {
    navigate('/404')
  }
  return <Component {...rest} />
}

export const CheckToken = (store = localStorageVar) => {
  if (store.getItem('token')) {
    let token = store.getItem('token')
    var decoded = jwt_decode(token)
    if (decoded.exp < Date.now() / 1000) {
      store.clear()
      window.location.origin = '/'
    } else {
      return true
    }
  }
  return false
}

// const basePath = 'https://magnum-be-staging.herokuapp.com/'
const basePath = process.env.GATSBY_BE_URL
//secured Api call
export function requestPostWithKey(data) {
  return axios
    .post(`${basePath + data[0]}`, data[1], authHeader())
    .then(response => response.data)
    .catch(error => {
      throw error
    })
}

export function requestGetWithKey(data, permissions = false, localStorageVar) {
  return axios
    .get(`${basePath + data[0]}`, authHeader())
    .then(response => ({
      message: response.data,
      status: response.status,
    }))
    .catch(async (error) => {
      if (permissions || error.message === 'Network Error') {
        return Promise.reject(error)
      }
      toast.error(error.response.data, {
        position: toast.POSITION.BOTTOM_LEFT,
      })
      if (error.message === "Request failed with status code 500") {
        let errorString = error.response.data;
        throw errorString
      }
      if (error.message === "Request failed with status code 404") {
        throw 404
      }
      return {
        message: error.response.data,
        status: error.response.status,
      }
    })
}

export function requestDelete(data) {
  return axios
    .delete(`${basePath + data[0]}`, { data: data[1] }, authHeader())
    .then(response => response.data)
    .catch(error => {
      throw error
    })
  //.catch(error => error.response.data)
}

export function requestDeleteWithKey(data) {
  return axios
    .delete(`${basePath + data[0]}`, authHeader())
    .then(response => response.data)
    .catch(error => {
      throw error
    })
  //.catch(error => error.response.data)
}

export function requestPostPatch(data) {
  return axios
    .patch(`${basePath + data[0]}`, data[1], authHeader())
    .then(response => response.data)
    .catch(error => {
      throw error
    })
  //.catch(error => error.response.data)
}
export function requestPutId(data) {
  return axios
    .put(`${basePath + data[0]}`, data[1], authHeader())
    .then(response => response.data)
    .catch(error => {
      throw error
    })
  //.catch(error => error.response.data)
}

//to build Header object
export function authHeader(store = localStorageVar) {
  if (store.getItem('token')) {
    const token = store.getItem('token')
    const Authorization = `Bearer ${token}`
    return { headers: { Authorization: Authorization } }
  }
  return {}
}
export const dowloadFile = (data, store = localStorageVar) => {
  let Authorization
  if (store.getItem('token')) {
    const token = store.getItem('token')
    Authorization = `Bearer ${token}`
  }
  return axios
    .request({
      method: 'GET',
      url: `${basePath + data[0]}`,
      responseType: 'blob',
      headers: { Authorization: Authorization },
    })
    .catch(async (error) => {
      if (error.message === "Network Error") {
        throw 503
      }
      if (error.message === "Request failed with status code 500") {
        let errorString = JSON.parse(await error.response.data.text());
        throw errorString
      }
      if (error.message === "Request failed with status code 404") {
        throw 404
      }
      throw error
    })
    .then(response => {
      let fileName = response.headers['content-disposition']?.split(';')[1]?.split('=')[1]?.split('"').join('')
      fileName = fileName ? fileName : 'export.csv'
      fileDownload(response.data, fileName)
    })
}
export const dowloadFilePDF = (data, store = localStorageVar) => {
  let Authorization
  if (store.getItem('token')) {
    const token = store.getItem('token')
    Authorization = `Bearer ${token}`
  }
  return axios
    .request({
      method: 'GET',
      url: `${basePath + data[0]}`,
      responseType: 'blob',
      headers: { Authorization: Authorization },
    })
    .catch(async (error) => {
      if (error.message === "Network Error") {
        throw 503
      }
      if (error.message === "Request failed with status code 500"){
        let errorString = JSON.parse(await error.response.data.text());
        throw errorString
      }
      if (error.message === "Request failed with status code 404") {
        throw 404
      }
      throw error
    })
    .then(response => {
      let fileName = response.headers['content-disposition']?.split(';')[1]?.split('=')[1]?.split('"').join('')
      fileName = fileName ? fileName : 'export.pdf'
      fileDownload(response.data, fileName)
    })
}
export const dowloadFilePDFWithParams = (data, store = localStorageVar) => {
  let config = authHeader();
  config.params = data[1];
  config.responseType = 'blob';
  return axios
    .get(`${basePath + data[0]}`, config)
    .catch(async (error) => {
      if (error.message === "Network Error") {
        throw 503
      }
      if (error.message === "Request failed with status code 500") {
        let errorString = JSON.parse(await error.response.data.text());
        throw errorString
      }
      if (error.message === "Request failed with status code 404") {
        throw 404
      }
      throw error
    })
    .then(response => {
      let fileName = response.headers['content-disposition']?.split(';')[1]?.split('=')[1]?.split('"').join('')
      fileName = fileName ? fileName : 'export.pdf'
      fileDownload(response.data, fileName)
    })
}
export const dowloadFileCSVWithParams = (data, store = localStorageVar) => {
  let header = authHeader();
  header.params = data[1];
  return axios
    .get(`${basePath + data[0]}`, header)
    .catch(async (error) => {
      if (error.message === "Network Error") {
        throw 503
      }
      if (error.message === "Request failed with status code 500") {
        let errorString = error.response.data;
        throw errorString
      }
      if (error.message === "Request failed with status code 404") {
        throw 404
      }
      throw error
    })
    .then(response => {

      let fileName = response.headers['content-disposition']?.split(';')[1]?.split('=')[1]?.split('"').join('')
      fileName = fileName ? fileName : 'export.csv'
      fileDownload(response.data, fileName)
    })
}
export const dowloadFileTXTWithParams = (data, store = localStorageVar) => {
  let header = authHeader();
  header.params = data[1];
  return axios
    .get(`${basePath + data[0]}`, header)
    .catch(async (error) => {
      if (error.message === "Network Error") {
        throw 503
      }
      if (error.message === "Request failed with status code 500") {
        let errorString = error.response.data;
        throw errorString
      }
      if (error.message === "Request failed with status code 404") {
        throw 404
      }
      throw error
    })
    .then(response => {
      let fileName = response.headers['content-disposition']?.split(';')[1]?.split('=')[1]?.split('"').join('')
      fileName = fileName ? fileName : 'export.txt'
      fileDownload(response.data, fileName)
    })
}
export const dowloadFileTSVWithParams = (data, store = localStorageVar) => {
  let header = authHeader();
  header.params = data[1];
  return axios
    .get(`${basePath + data[0]}`, header)
    .catch(async (error) => {
      if (error.message === "Network Error") {
        throw 503
      }
      if (error.message === "Request failed with status code 500") {
        let errorString = error.response.data;
        throw errorString
      }
      if (error.message === "Request failed with status code 404") {
        throw 404
      }
      throw error
    })
    .then(response => {
      let fileName = response.headers['content-disposition']?.split(';')[1]?.split('=')[1]?.split('"').join('')
      fileName = fileName ? fileName : 'export.tsv'
      fileDownload(response.data, fileName)
    })

}
export const downloadTextFormat = (data, store = localStorageVar) => {
  let Authorization
  if (store.getItem('token')) {
    const token = store.getItem('token')
    Authorization = `Bearer ${token}`
  }
  return axios
    .request({
      method: 'GET',
      url: `${basePath + data[0]}`,
      responseType: 'blob',
      headers: { Authorization: Authorization },
    })
    .catch(async (error) => {
      if (error.message === "Network Error") {
        throw 503
      }
      if (error.message === "Request failed with status code 500") {
        let errorString = error.response.data;
        throw errorString
      }
      if (error.message === "Request failed with status code 404") {
        throw 404
      }
      throw error
    })
    .then(response => {
      let fileName = response.headers['content-disposition']?.split(';')[1]?.split('=')[1]?.split('"').join('')
      fileName = fileName ? fileName : 'export.txt'
      fileDownload(response.data, fileName)
    })
}
export const downloadTsvFormat = (data, store = localStorageVar) => {
  let Authorization
  if (store.getItem('token')) {
    const token = store.getItem('token')
    Authorization = `Bearer ${token}`
  }
  return axios
    .request({
      method: 'GET',
      url: `${basePath + data[0]}`,
      responseType: 'blob',
      headers: { Authorization: Authorization },
    })
    .catch(async (error) => {
      if (error.message === "Network Error") {
        throw 503
      }
      if (error.message === "Request failed with status code 500") {
        let errorString = error.response.data;
        throw errorString
      }
      if (error.message === "Request failed with status code 404") {
        throw 404
      }
      throw error
    })
    .then(response => {
      let fileName = response.headers['content-disposition']?.split(';')[1]?.split('=')[1]?.split('"').join('')
      fileName = fileName ? fileName : 'export.tsv'
      fileDownload(response.data, fileName)
    })
}
//file upload
export const ApiCallFormData = (url, data, onUploadProgress, store = localStorageVar) => {
  let formData = new FormData()
  let Authorization
  formData.append('file', data[0])
  if (store.getItem('token')) {
    const token = store.getItem('token')
    Authorization = `Bearer ${token}`
  }
  return axios
    .request({
      method: 'post',
      url: `${basePath + url}`,
      data: formData,
      onUploadProgress,
      headers: { Authorization: Authorization },
    })
    .then(response => {
      return response
    })
}
//getwithParams
export function requestGetWithParam(data, permissions = false, localStorageVar) {
  let header = authHeader();
  header.params = data[1];
  return axios
    .get(`${basePath + data[0]}`, header)
    .then(response => ({
      message: response.data,
      status: response.status,
    }))
    .catch(error => {
      console.log(error)
      toast.error(error.response.data, {
        position: toast.POSITION.BOTTOM_LEFT,
      })
      if (error.message === "Network Error") {
        throw 503
      }
      if (error.message === "Request failed with status code 500") {
        let errorString = error.response.data;
        throw errorString
      }
      if (error.message === "Request failed with status code 404") {
        throw 404
      }
      return {
        message: error.response.data,
        status: error.response.status,
      }
    })
}

//interval handler for polling
export function useInterval(callback, delay) {
  const savedCallback = useRef()
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    function tick() {
      savedCallback.current()
    }
    if (delay !== null) {
      const id = setInterval(tick, delay)
      return () => {
        clearInterval(id)
      }
    }
  }, [callback, delay])
}

const loadingMessages = [
  'Save water, drink wine',
  'Working nine to wine',
  'It’s wine o’clock somewhere',
  'Let’s go wine tasting on the couch',
  'Wine is my spirit animal',
  'Coffee keeps me going until it’s acceptable to drink wine',
  'I enjoy long romantic walks down the wine aisle',
  'Wine improves with age. I improve with wine.',
  'Oops! Did I buy wine instead of milk again?',
  'I’ll be there in a prosecco!',
  'You had me at merlot',
  'How merlot can you go?',
  'Tonight’s forecast… 99% chance of wine',
  'Any friend of wine is a friend of mine',
  'Wine a little, laugh a lot',
  
  'Love the wine you’re with',
  'Go chard or go home',
  'Slay all day then cabernet',
  'Stop and drink the rosé',
  'Sip me by one more time'
]

export const randomMessage = () => {
  return loadingMessages[Math.floor(Math.random() * loadingMessages.length)]
}

//global delay for Typed messages
export const typedDelay = '^1000'