import React from 'react'
import { Col, Container, Row } from 'reactstrap'
import { Link } from 'gatsby'

const Footer = () => {
  const version = '2.10.1'
  return (
    // <footer>
    <Container>
      <Row noGutters className="mt-3 mb-3">
        <Col sm="auto">
          <p className="mb-0">
            {new Date().getFullYear()} &copy; Brunch {'  '} <br className="d-none" />
            <Link to="/terms" className="ml-2">
              Terms &amp; Conditions{'  '} <br className="d-none" />
            </Link>
            <Link to="/privacy" className="ml-2">
              Privacy <br className="d-none" />
            </Link>
          </p>
        </Col>
      </Row>
    </Container>

    // </footer>
  )
}

export default Footer
